<template>
    <div class="chartset">
        <van-nav-bar :title="$t('Mt4ApiTrade.newAccount')" :left-text="$t('toback')" left-arrow
                     @click-left="back"/>
        <div class="page member">

            <div class="cblock">
                <van-cell-group>
                    <van-cell to="/loginbypwd" is-link center>
                        <van-col span="24" class="user-info-text">
                            <div class="title">
                                <span class="title">{{$t('Mt4ApiTrade.openNewAccount')}}</span>
                            </div>
                            <div class="description">
                                <span>{{$t('Mt4ApiTrade.openNewAccountDesc')}}</span>
                            </div>
                        </van-col>
                    </van-cell>
                </van-cell-group>


                <van-cell-group>
                    <van-cell to="/changeaccount" is-link center>
                        <van-col span="24" class="user-info-text">
                            <div class="title">
                                <span>{{$t('Mt4ApiTrade.loginWithNewAccount')}}</span>
                            </div>
                            <div class="description">
                                <span>{{$t('Mt4ApiTrade.loginWithNewAccountDesc')}}</span>
                            </div>
                        </van-col>
                    </van-cell>
                </van-cell-group>
            </div>

            
        </div>




    </div>
</template>
<script>
    export default {
        data() {
            return {
                

            };
        },
        computed: {},
        mounted() {
            this.$store.commit("toggleTabbarShow", false);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");
            
        },
        created() {

        },
        beforeDestroy() { },
        methods: {
            back() {
                
                this.$router.push({
                    path: '/settings',
                    
                })
            },
            
            
        },
    };
</script>


<style scoped>
    #app {
        background-color: #f7f7f7;
    }

    


    .page {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .cblock {
        margin: 0 10px;
    }



    .margin-top {
        margin-top: 0.5rem;
    }

    .cblock:last-child {
        padding-bottom: 0.5rem;
    }
    .description {font-size:12px;
    }
    .title{font-size:16px;}
    
</style>
<style>
    .chartset .van-nav-bar {
        background-color: #13b5b1;
    }

    .chartset .van-nav-bar__title {
        color: #fff;
    }

    .chartset .van-nav-bar .van-icon, .chartset .van-nav-bar__text {
        color: #fff;
    }
</style>

import { render, staticRenderFns } from "./NewAccount.vue?vue&type=template&id=b783c816&scoped=true&"
import script from "./NewAccount.vue?vue&type=script&lang=js&"
export * from "./NewAccount.vue?vue&type=script&lang=js&"
import style0 from "./NewAccount.vue?vue&type=style&index=0&id=b783c816&scoped=true&lang=css&"
import style1 from "./NewAccount.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b783c816",
  null
  
)

export default component.exports